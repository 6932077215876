define([], function() {

  var showcase = function() {
    var self = this;

    var _config = {
      titleContainerSelector: '[data-showcase-title]'
    };

    var _init = function() {
      var titleContainer = document.querySelector(self.config.titleContainerSelector);

      if (titleContainer) {
        var newTitle = titleContainer.getAttribute('data-showcase-title');
        if (newTitle) {
          document.title = newTitle;
        }
      }
    };

    self.config = _config;
    self.init = _init;
  };

  return showcase;
});
